import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'

const info = [
  {
    question: 'How Long will my appointment be?',
    answer: `<p>Appointments are booked for 15, 30 or 60 minutes, depending on the issue/procedure you wish to discuss with Dr Sellars.</p><p>Cosmetic and Breast Reconstruction consultations generally go for 45-60 minutes.</p><p>Skin cancer and other reconstructive procedure consultations are usually for 15-30 minutes. Biopsies are 30 minutes.</p><p>We appreciate some patients may feel awkward discussing the concern with the secretary when making an appointment, however it is essential that you inform the staff of the procedure or issue you want to discuss with Dr Sellars, so the correct amount of time can be allocated to you.</p>
    <p><strong>For skin cancers and mole removal - The initial appointment will be a consultation only,</strong> and no extra time for any procedure can be allocated at this time, (eg excision of lesion). Once Dr Sellars has assessed the area of concern, another appointment for excision can be made. This will depend on the site and size of lesion and age and medical condition of patient. The excision of a lesion may also require a more complicated approach other than a simple cut out and may require a skin graft or flap repair</p>
    <p>Other factors to consider are - can it be done in the rooms under local anaesthetic or is a sedation or general anaesthetic required, which would mean a hospital admission.</p><p>Dr Sellars can perform biopsies at time of consultation if required.</p>`,
    isHtml: true,
  },
  {
    question: 'What do I need to bring to my appointment?',
    isHtml: true,
    answer: `<ul><li>Referral from your GP or specialist (where relevant eg Dermatologist)</li>
      <li>Your Medicare Card</li>
      <li>Your Health Insurance Card (if applicable)</li>
      <li>A list of current medications</li>
      <li>Bring any scans or x-rays relating to your reason for consultation (eg breast ultrasound or mammograms)</li>
      <li>Please print and complete the attached document: <a href="/patient-information.doc" target="_blank" style="text-decoration: underline; color: #4099ff; font-weight: 600;">Our patient information document</a></li>
      <li>We encourage you to familiarise yourself with your procedure or treatment of interest by browsing the sections of this website.</li></ul>`,
  },
  {
    question: 'What does my consultation cost? (As at January 2024)',
    isHtml: true,
    answer: `<p>	A consultation for cosmetic procedures or for breast reconstruction costs $385. </p>
      <p>A consultation for reconstructive procedures such as skin cancer, mole removal or scar revision costs $250.</p>
      <p>A follow up appointment costs $110. </p>
      <p>If you come with a current GP referral you will be able to claim Medicare rebates on these appointments. $81.30 for an initial consult, and $40.85 for follow-up consults. Medicare rebates apply to consultations and in rooms procedures (outpatient). Health fund rebates apply to hospital admissions and procedures (inpatient).</p>`,
  },

  {
    question: 'Do I need a referral?',
    isHtml: true,
    answer: `<p>A referral from your local GP is a legal requirement from 1st July 2023 prior to any consultation with a plastic surgeon, even if it is a cosmetic procedure you are wanting to discuss. The referral forms part of your treatment plan including your medical history, medications taken and any other health issues of concern.</p> 
      <p>A referral from your GP will allow you to claim a Medicare rebate for Dr Sellars’ consultation. Also for common cosmetic operations which have medical benefits (such as breast reductions and implant revisions) this will allow you to claim the Medicare/health insurance rebate for surgeon/anaesthetist fee, as well as coverage of hospital fees (where your private health insurance policy is applicable). </p>
      <p>A referral is not required if you are coming for injectable treatments such as anti-wrinkle and dermal filler injections. </p>`,
  },
  {
    question: 'When is a new referral required to see a specialist?',
    isHtml: true,
    answer: `<p>GP referrals will have a 12 month validity for a single issue, ie Initial consultation and any follow-up with specialist within the 12 months for the same issue. </p>
    <p>Medicare requires a new GP referral to see a specialist for every new issue to be  discussed (such as a skin lesion/cancer). </p>
    <p>This means a patient cannot return to see specialist over the course of 12 months for a variety of different issues on the initial referral.</p>
    <p><strong>GP referrals - 12 months</strong></p> 
    <p><strong>Dermatologist referrals - 3 months</strong></p>
      `,
  },
  {
    question: 'What if I need to cancel or change my appointment?',
    isHtml: true,
    answer: `<p>We understand that the best of plans may sometimes need to be changed as unexpected things can occur.</p>
      <p>We request that you give us at least 48 hours notice if you are unable to attend your appointment. This allows your consultation time to be offered to other patients. We generally have a wait list of patients requesting earlier appointments if they become available. </p>
      <p>If a mobile number is provided, a text reminder will be sent to you a couple of days prior to your scheduled appointment, but prior notice of a cancellation is appreciated.</p>
      `,
  },
  {
    question: 'What happens during my first consultation?',
    isHtml: true,
    answer: `<p>At your first consultation Dr Sellars will discuss your concerns, and go through a full medical history and physical examination. He will also discuss your treatment options, including a discussion of risks, and benefits. </p>
      <p>For cosmetic consultations Dr Sellars will show you before and after photos of patients having undergone procedures you are enquiring about. Many patients don’t want their photos displayed on the website, however are happy to be shown in private consultation.</p>
      <p>We will always ask you to see Dr Sellars at least twice before deciding to have a cosmetic procedure as we believe the decision to have surgery is a significant one, and you should consider all options, have the opportunity to ask questions, and have time to understand and ponder over the risks and benefits before committing to surgery.</p>
      <p>After your first consultation, an estimate of surgical fees will be provided to you as a written quote (usually emailed).
      <p>Be assured Dr Sellars and our office staff will NEVER put pressure on you to commit to any procedure.</p>
      `,
  },
  {
    question: 'Returning Patients',
    isHtml: true,
    answer: `<p>You may need a new referral if you are a returning patient as GP referrals expire after 12 months and specialist referrals such as a dermatologist expire after 3 months.</p>
      <p>If the consultation is about a new procedure or is booked 12 months after discharge from a previous procedure, a new consultation fee will be charged.</p>`,
  },
]



export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Patient Information - Dr Sellars - Plastic Surgery Sydney</title>
      <meta
        name="description"
        content="Still have questions? We've got the answers, have a read through our patient information to find exactly what your looking for."
      />
    </Helmet>
    <Header>
      <h1>Patient Information</h1>
      <Img
        fluid={data.teamSmall.childImageSharp.fluid}
        style={{ borderRadius: 8, marginBottom: 5, marginTop: 60 }}
      />
    </Header>
    <FAQs faqs={info} />
    <CTA
      title="Want to know more?"
      subtitle="Ask the experts."
      button="Contact Us"
    />
  </Layout>
)

export const query = graphql`
  query PatientInfoQuery {
    teamSmall: file(relativePath: { eq: "team-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
